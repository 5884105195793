export const environment = {
  production: false,
  mode:'QA',
  treebuLedger: "https://qa.treebucapital.com",

  // Servers
  treebuHasura: "https://qa.treebucapital.com",
  treebuCapital: "https://qa.treebucapital.com",
  treebuCrypto: "https://qa.treebucapital.com",
  prefixTreebuHasura: "api/rest",
  prefixTreeCrypto: "api/ms-crypto/v1",
  prefixTreeLedger: "api/treebu-ledger/v1",
  prefixBasic: "api",

  // Media Files
  multimedia: "/assets",

  //Recaptcha
  siteKey: "6LeDSMYpAAAAAOZZT1Wv2gZ6B0UOMkPq1S-rbV-6",
  secretKey: "6LeDSMYpAAAAAJwVksnmhu0_M91fHOL8Osbu4j7Q",

  //Metamap
  flowId: "64a411c2a0e20a001c9bb6ff",

  // Firebase
  firebaseConfig : {
    apiKey: "AIzaSyAOVWwKVMu3gEwQwTzfapvazIJdUFsoC-E",
    authDomain: "treebu-qa-d04a3.firebaseapp.com",
    projectId: "treebu-qa",
    storageBucket: "treebu-qa.appspot.com",
    messagingSenderId: "878560258914",
    appId: "1:878560258914:web:1518a730a682236c015a3b",
    measurementId: "G-1SSJKNFSYD"
  },

  timerRefreshToken: 3300,
};
