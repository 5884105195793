import { environment } from "../../../environments/environment";

// Servers
export const TREEBU_LEDGET = environment.treebuLedger;
export const TREEBU_HAUSRA = environment.treebuHasura;
export const TREEBU_CAPITAL = environment.treebuCapital;
export const TREEBU_CRYPTO = environment.treebuCrypto;
export const PREFIX_TREEBU_HASURA = environment.prefixTreebuHasura;
export const PREFIX_TREEBU_CRYPTO = environment.prefixTreeCrypto;
export const PREFIX_TREEBU_LEDGER = environment.prefixTreeLedger;
export const PREFIX_BASIC = environment.prefixBasic;

// Media
export const MULTIMEDIA = environment.multimedia;

// FIREBASE
export const FIREBASE_CONFIG = environment.firebaseConfig;
