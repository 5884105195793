import { MULTIMEDIA } from "../constants/environment.constant";


export class AppResourcesConfig {
  public static image(image: string): string {
    return `${MULTIMEDIA}/images/${image}`;
  }

  public static icon(icon: string): string {
    return `${MULTIMEDIA}/icons/${icon}`;
  }

  public static menuIcon(icon: string): string {
    return `${MULTIMEDIA}/icons/menu/${icon}`;
  }

  public static logo(logo: string): string {
    return `${MULTIMEDIA}/logos/${logo}`;
  }

  public static countryCodeFlag(flag: string): string {
    return `${MULTIMEDIA}/flags/${flag}.png`;
  }
}
